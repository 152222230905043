@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Mono:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title 
{   
    padding: 1rem;
    font-size: 1rem;
    font-family: "Roboto Mono", monospace;
    font-weight: 500;
    margin-left: 4rem;
    display: flex;
    color: #33582a;
     text-shadow: 3px 3px 3px #eeccae;
    
}

.contactInfo{
  padding: 1rem;
  font-size: 1rem;
  font-family: "Roboto Mono", monospace;
  font-weight: 600;
  margin-left: 4rem;
  display: flex;
  color: #747474;
}

.contactInfoTitle{
  padding: 1rem 1rem 0 1rem;
  font-size: 12px;
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  margin-left: 4rem;
  display: flex;
  color: #747474;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Jumbo {
    background-image: url(/static/media/title_bg2.917a0412.jpg);
    background-color: rgba(0, 0, 0, 0.3);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5rem;
    text-align: center;
}

#title 
{   
    padding: 1rem;
    font-size: 3rem;
    font-family: "Roboto Mono", monospace;
    font-weight: 600;
    font-optical-sizing: auto;
    margin: 0;
    display: flex;
    color: #FF765F;
    justify-content: center;
     text-shadow: 3px 3px 3px #eeccae;
    
}

.Design {
 box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
 padding: 2rem;
 width: 25rem;
 height: 22rem;
 text-align: center;
 top: 50px;
 position: relative;
 color: black;
 background-color: white;
 border-radius: 40px;

}
.formDialog{
  margin: 1.5rem;
}

a {
  font-size: smaller;
}





.Design {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 2rem;
  width: 500rem;
  height: auto;
  text-align: center;
  
  position: relative;
  color: black;
  background-color: white;
  border-radius: 40px;
 
 }
   .formDialog{
     margin: 1.5rem;
   }
   
   a {
     font-size: smaller;
   }

   
.registerButton  {
  text-align: center;
  
}

.navBar{
    font-size: large;
    margin: 0.5rem ;
}

.navLogo{
    float: left !important;
    margin-top: -15px !important;
}


#navHeading{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 4rem;
    padding-left: 2rem;
}


.card {
    margin-left: 5rem;
    margin-top: 6rem;
}


.container{
    -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
    height: 100vh;
}

section{
    scroll-snap-align: start;
    height: 100vh;
}

.containerOneHeading{
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    font-family: "Signika", sans-serif;
    font-size: 40px;

}

.containerOneBody{
    font-family: "Signika", sans-serif;
    font-size: 17px;
    font-weight: 300;
    margin-left: 1rem;
    text-wrap: pretty;
}

.containerTwo{
    background-color: #ffffff;
}

.containerThree{
    background-color: #dce1e9;
}
.popularCard{
    width: auto;
    padding: 1rem;
    height: auto;
    border-radius: 20px;
    display: flex;    
    grid-gap: 2rem;    
    gap: 2rem;
    justify-content: center;
}


.resCard{
    width: 300px;
    height: 350px;
    border-radius: 30px !important;
    cursor: pointer;    
}


.secTitle{
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    font-family: "Signika", sans-serif;
    font-size: 30px;
    font-weight: 600;
    margin: 2rem;

}


.resCardBody{
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.resName{
    font-family: "Signika", sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;

}

.resDesc{
    font-family: "Signika", sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: rgb(160, 160, 160);
    margin-bottom: 5px;
}

.restInfo {
    margin-top: 2rem;
    height: 17rem;
    background-color: hsl(175, 41%, 79%);
    border-radius: 0%;
    padding: 2.5rem;
}

.image {
    min-height: auto;
    max-height: 200px; 
    padding-bottom: 10px;
    padding-left: 15px;
     
}

h3{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
}
p {
    padding-left: 17px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    font-size: larger;
    
}

.dishCard {
    height: 10rem;
    width: 40rem;
    border-color:#343A40;
    font-weight: 500;
    font-family: 'Josefin Sans', sans-serif;
    
}

.dishName{
    padding-left: 3rem;
}

.dishImage{
    min-height: auto;
    max-height: 120px; 
    padding-bottom: 10px;
    padding-left: 60px;
    border-radius: 40px 40px 0 0;
     
}

.resHeading{
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    font-family: "Signika", sans-serif;
    font-size: 30px;

}

.resSubHeading{
    font-family: "Signika", sans-serif;
    font-size: 17px;
    font-weight: 300;
    justify-content: center;
    display: flex;
    text-wrap: pretty;
}




.dishCardBody{
    padding: 1rem;
    display: flex;    
    text-align: center;
    flex-direction: column;
}

.dishName{
    font-family: "Signika", sans-serif;
    padding: 0;
    font-weight: 600;    
    font-size: 18px;
    margin-bottom: 5px;

}

.dishCard{
    width: 300px;
    height: 350px;
    border-radius: 30px !important;
}

.dishDesc{
    font-family: "Signika", sans-serif;
    font-weight: 600;
    padding: 0.5rem;
    font-size: 13px;
    color: rgb(160, 160, 160);
    margin-bottom: 5px;
}

.dishPrice{
    font-family: "Signika", sans-serif;
    font-weight: 600;
    padding-left: 1rem;
    font-size: 17px;
}
