@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap');

.popularCard{
    width: auto;
    padding: 1rem;
    height: auto;
    border-radius: 20px;
    display: flex;    
    gap: 2rem;
    justify-content: center;
}


.resCard{
    width: 300px;
    height: 350px;
    border-radius: 30px !important;
    cursor: pointer;    
}


.secTitle{
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    font-family: "Signika", sans-serif;
    font-size: 30px;
    font-weight: 600;
    margin: 2rem;

}


.resCardBody{
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.resName{
    font-family: "Signika", sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;

}

.resDesc{
    font-family: "Signika", sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: rgb(160, 160, 160);
    margin-bottom: 5px;
}