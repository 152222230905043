@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.navBar{
    font-size: large;
    margin: 0.5rem ;
}

.navLogo{
    float: left !important;
    margin-top: -15px !important;
}


#navHeading{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 4rem;
    padding-left: 2rem;
}


.card {
    margin-left: 5rem;
    margin-top: 6rem;
}


.container{
    scroll-snap-type: y mandatory;
    height: 100vh;
}

section{
    scroll-snap-align: start;
    height: 100vh;
}

.containerOneHeading{
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    font-family: "Signika", sans-serif;
    font-size: 40px;

}

.containerOneBody{
    font-family: "Signika", sans-serif;
    font-size: 17px;
    font-weight: 300;
    margin-left: 1rem;
    text-wrap: pretty;
}

.containerTwo{
    background-color: #ffffff;
}

.containerThree{
    background-color: #dce1e9;
}