body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title 
{   
    padding: 1rem;
    font-size: 1rem;
    font-family: "Roboto Mono", monospace;
    font-weight: 500;
    margin-left: 4rem;
    display: flex;
    color: #33582a;
     text-shadow: 3px 3px 3px #eeccae;
    
}

.contactInfo{
  padding: 1rem;
  font-size: 1rem;
  font-family: "Roboto Mono", monospace;
  font-weight: 600;
  margin-left: 4rem;
  display: flex;
  color: #747474;
}

.contactInfoTitle{
  padding: 1rem 1rem 0 1rem;
  font-size: 12px;
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
  margin-left: 4rem;
  display: flex;
  color: #747474;
}

