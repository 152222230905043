@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Mono:ital@0;1&display=swap');

.Jumbo {
    background-image: url("../../img/title_bg2.jpg");
    background-color: rgba(0, 0, 0, 0.3);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.5rem;
    text-align: center;
}

#title 
{   
    padding: 1rem;
    font-size: 3rem;
    font-family: "Roboto Mono", monospace;
    font-weight: 600;
    font-optical-sizing: auto;
    margin: 0;
    display: flex;
    color: #FF765F;
    justify-content: center;
     text-shadow: 3px 3px 3px #eeccae;
    
}
