
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;700&display=swap');


.restInfo {
    margin-top: 2rem;
    height: 17rem;
    background-color: hsl(175, 41%, 79%);
    border-radius: 0%;
    padding: 2.5rem;
}

.image {
    min-height: auto;
    max-height: 200px; 
    padding-bottom: 10px;
    padding-left: 15px;
     
}

h3{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
}
p {
    padding-left: 17px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    font-size: larger;
    
}

.dishCard {
    height: 10rem;
    width: 40rem;
    border-color:#343A40;
    font-weight: 500;
    font-family: 'Josefin Sans', sans-serif;
    
}

.dishName{
    padding-left: 3rem;
}

.dishImage{
    min-height: auto;
    max-height: 120px; 
    padding-bottom: 10px;
    padding-left: 60px;
    border-radius: 40px 40px 0 0;
     
}

.resHeading{
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    font-family: "Signika", sans-serif;
    font-size: 30px;

}

.resSubHeading{
    font-family: "Signika", sans-serif;
    font-size: 17px;
    font-weight: 300;
    justify-content: center;
    display: flex;
    text-wrap: pretty;
}




.dishCardBody{
    padding: 1rem;
    display: flex;    
    text-align: center;
    flex-direction: column;
}

.dishName{
    font-family: "Signika", sans-serif;
    padding: 0;
    font-weight: 600;    
    font-size: 18px;
    margin-bottom: 5px;

}

.dishCard{
    width: 300px;
    height: 350px;
    border-radius: 30px !important;
}

.dishDesc{
    font-family: "Signika", sans-serif;
    font-weight: 600;
    padding: 0.5rem;
    font-size: 13px;
    color: rgb(160, 160, 160);
    margin-bottom: 5px;
}

.dishPrice{
    font-family: "Signika", sans-serif;
    font-weight: 600;
    padding-left: 1rem;
    font-size: 17px;
}