.Design {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 2rem;
  width: 500rem;
  height: auto;
  text-align: center;
  
  position: relative;
  color: black;
  background-color: white;
  border-radius: 40px;
 
 }
   .formDialog{
     margin: 1.5rem;
   }
   
   a {
     font-size: smaller;
   }

   
.registerButton  {
  text-align: center;
  
}
